import axios from "./axios.js"

export class MailTemplate {

    static getMailTemplates() {
        return axios.get("/mailtemplates");

    }
    static getMailTemplate(id) {
        let defaultURL = `/mailtemplates/${id}`;
        return axios.get(defaultURL);
    }
    static createMailTemplate(mailtemplate) {
        let defaultURL = `/mailtemplates`;
        return axios.post(defaultURL, mailtemplate);
    }
    static updateMailTemplate(mailtemplate, id) {
        let defaultURL = `/mailtemplates/${id}`;
        return axios.put(defaultURL, mailtemplate);
    }
    static deleteMailTemplate(id) {
        let defaultURL = `/mailtemplates/${id}`;
        return axios.delete(defaultURL);
    }

}